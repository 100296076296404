{
  "insights": "Insights",
  "sent-to": "Message sent to {{- to}}",
  "quick-picks": "Quick Picks",
  "all-insights": "All Open Insights",
  "wheres-the-rest-title-one": "This is it?",
  "wheres-the-rest-title-two": "Where’s everything else?",
  "wheres-the-rest-action": "Got It, Thanks",
  "wheres-the-rest-body": "You’re looking at your “Quick Picks” insights right now. Think of it as a tuned productivity mode where we serve up five insights that we recommend you knock out today. You can always do more of course. Or, you can even filter your view to see all of your open insights, if you’re more into a Choose-Your-Own-Adventure workflow.",
  "gimme-more-action": "Gimme More!",
  "gimme-more-body": "There’s more where that came from, if you’re up for it. Click the button for five more great opportunities to connect with your clients.",
  "gimme-more-title-one": "Nicely done!",
  "gimme-more-title-two": "You made quick work of those insights.",
  "all-caught-up-title-one": "Whoa—imagine that.",
  "all-caught-up-title-two": "You’re all caught up.",
  "all-caught-up-body": "Wow, we’re impressed. As of right now, you’re all caught up with outstanding insights and next actions for your clients. \n Check back again soon.",
  "beta-banner-body": "This feature is currently in beta as we add more capabilities. Tell us how we can improve it!",
  "beta-banner-button": "Send Feedback"
}
