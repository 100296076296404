{
  "inbox-page-meta-title": "Messaging",
  "messaging-index-page-meta-title": "Messaging",
  "compose-message-page-meta-title": "Messaging",
  "quick-check-in-page-meta-title": "New Message",

  "messaging-start-conversation-illustration-text": "Select a client on the left to keep the conversation going, or send a message to start a new conversation.",
  "messaging-start-conversation-illustration-action": "Send a New Message",

  "inbox-sidebar-search-placeholder": "Find client by name...",
  "inbox-sidebar-message-sending_one": "Sending 1 message...",
  "inbox-sidebar-message-sending_other": "Sending {{count}} messages...",

  "compose-banner-text": "New Message",
  "compose-view-description": "Type your message below or just\n<a>ask what financial question is on their mind today.</a>",
  "compose-view-message-box-input-placeholder": "Type your message...",
  "compose-view-templates-menu-quick-check-in-option-title": "Quick Check-in",
  "compose-view-templates-menu-quick-check-in-option-description": "A great way to engage your clients\nand ask if anything’s on their mind.",
  "compose-view-error-action-button": "View Details",

  "message-create-error-modal-title": "Unable to Send Message",
  "message-create-error-modal-body": "We weren’t able to send your message to the clients listed below. Would you like to try again?",
  "message-create-error-modal-action": "Try Again",

  "compose-recipient-box-to": "To:",
  "compose-recipient-box-input-placeholder": "Add a recipient",

  "recipients-list-resend-invitation": "Resend Invitation",
  "recipients-list-more-recipients-button": "{{count}} more",
  "recipients-list-invite-to-elements": "Invite to Elements",
  "recipients-list-no-autocompleted": "No clients have been found",

  "add-additional-clients-sideover-search-select-all": "Select All",
  "add-additional-clients-sideover-heading": "Add Additional Clients",
  "add-additional-clients-sideover-search-placeholder": "Find by name...",
  "add-additional-clients-sideover-description": "Don’t worry—this isn’t a group text. Each client will receive a private message from you in the Elements app.",

  "messaging-empty-page-title": "Welcome to Messages",
  "messaging-empty-page-description": "This is where you can send and reply to in-app messages with your clients.\n\nGet started with the button below!",
  "messaging-empty-page-action": "Start a Conversation",

  "quick-check-in-title": "Quick Check-In",
  "quick-check-in-text": "Engage your clients by asking what financial question is on their mind.",
  "quick-check-in-recipient-list-label": "Recipients",
  "quick-check-in-recipient-list-placeholder": "Search for client by name or email",
  "quick-check-in-add-additional-clients-button": "Add Additional Clients",
  "quick-check-in-message": "Are there any financial questions that have been on your mind lately?",
  "quick-check-in-message-box-label": "Message",
  "quick-check-in-message-box-caption": "Not exactly how you’d say it? No worries—just edit the text to better fit your style.",
  "quick-check-in-one-tap-reply-toggle-value": "One-Tap Reply",
  "quick-check-in-one-tap-reply-toggle-label": "Response Type",
  "quick-check-in-response-option-one": "Yes. I’ve been thinking about...",
  "quick-check-in-response-option-two": "I’d prefer to chat. Will you call me?",
  "quick-check-in-response-option-three": "Thanks for checking. I’m all good!",
  "quick-check-in-response-options-title": "Response Options",
  "quick-check-in-response-options-add-button": "Add Additional Option",
  "quick-check-in-response-options-max-disclaimer": "You’ve reached the maximum number of Quick Reply options.",
  "quick-check-in-phone-preview-button": "Send",
  "quick-check-in-phone-preview-pill": "Client Preview",

  "conversation-download-full-history": "Download Full Message History",
  "conversation-mute-button": "Mute",
  "conversation-mute-tooltip": "Turn off all notifications for this conversation.",
  "conversation-un-mute-tooltip": "Get notifications for all new messages\nin this conversation.",
  "conversation-un-mute-button": "Notify Me",
  "conversation-message-box-placeholder": "Message to {{- participantNames}}",
  "conversation-new-message-divider": "New",
  "conversation-message-read": "Read",
  "conversation-message-delivered": "Delivered",

  "conversation-inline-error-description": "Unable to send. Would you like to try again?",
  "conversation-inline-error-resend": "Resend",
  "conversation-inline-error-delete": "Delete Message",

  "not-group-message-text": "Don't worry-this isn't a group text. Each client will receive a private message from you in the Elements app.",
  "conversation-no-elements-account-block-title": "Household Has No Elements Accounts",
  "conversation-no-elements-account-block-body": "New messages can't be sent to this household because they no longer have an Elements account. This typically happens because a member of your firm revoked their access or the clients deleted their accounts.\n\nIf you'd like to send a new message, you need to re-invite them to Elements.",

  "only-in-mobile-app-error-title": "Messages are only in the mobile app.",
  "only-in-mobile-app-error-subtitle": "Check the Elements app on your phone. Currently messages from your advisor are not available in your desktop browser. "
}
