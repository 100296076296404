import acceptInvite from '../public/locales/en/acceptInvite.json';
import accountDetails from '../public/locales/en/accountDetails.json';
import auth from '../public/locales/en/auth.json';
import client from '../public/locales/en/client.json';
import clients from '../public/locales/en/clients.json';
import editNotes from '../public/locales/en/editNotes.json';
import error from '../public/locales/en/error.json';
import forAdvisor from '../public/locales/en/forAdvisor.json';
import guides from '../public/locales/en/guides.json';
import inAppMessaging from '../public/locales/en/inAppMessaging.json';
import insight from '../public/locales/en/insight.json';
import insights from '../public/locales/en/insights.json';
import integrations from '../public/locales/en/integrations.json';
import invitations from '../public/locales/en/invitations.json';
import onePagePlan from '../public/locales/en/onePagePlan.json';
import reports from '../public/locales/en/reports.json';
import saod from '../public/locales/en/saod.json';
import signIn from '../public/locales/en/signIn.json';
import settings from '../public/locales/en/settings.json';
import dashboard from '../public/locales/en/dashboard.json';
import { elementsPackageI18nConfigOptions } from '@elements-financial/elements';

export default {
  returnNull: false,
  supportedLngs: ['en'],
  defaultNS: 'common',
  fallbackLng: 'en',
  react: { useSuspense: false },
  resources: {
    en: {
      acceptInvite,
      accountDetails,
      auth,
      client,
      clients,
      editNotes,
      error,
      forAdvisor,
      guides,
      inAppMessaging,
      insight,
      insights,
      integrations,
      invitations,
      onePagePlan,
      reports,
      saod,
      signIn,
      settings,
      dashboard,
      ...elementsPackageI18nConfigOptions.resources.en,
    },
  },
};
