{
  "sign-in": "Sign In",
  "meta-title": "Elements Financial Monitoring | Customer Sign In",
  "meta-description": "Elements customers, sign in here to access your web app.",
  "forgot-password-meta-title": "Elements Financial Monitoring | Forgot Password",
  "reset-password-meta-title": "Elements Financial Monitoring | Reset Password",
  "new-password-auth-challenge-meta-title": "Elements Financial Monitoring | New Password",
  "mfa-auth-challenge-meta-title": "Elements Financial Monitoring | MFA",
  "invalid-credentials-error-heading": "The Email or Password you entered was incorrect.",
  "invalid-credentials-error-subheading": "Please try again.",
  "email": "Email",
  "forgot-password-q": "Forgot your password?",
  "reset-password-header": "No worries. Let's reset that password.",
  "reset-password-action": "Send Password Reset Email",
  "return-to-sign-in": "Return to Sign In",
  "check-your-inbox-heading": "Check your inbox!",
  "check-your-inbox-body": "If an account exists with that address, you'll get an email to reset your password very soon. When you get it, just click the magic reset link.",
  "welcome-back": "Welcome back! Let's get you a new password.",
  "new-password": "New Password",
  "save-and-sign-in": "Save & Sign In",
  "lets-get-a-password": "Let’s get you a good password.",
  "force-reset-password-title": "Hi {{name}}! 👋",
  "software-token-mfa-prompt": "Enter the code from authenticator \n \"{{friendlyDeviceName}}.\"",
  "verify-code": "Verify Code",
  "invalid-totp-code": "Incorrect code. Please double-check your verification code and try again."
}
