{
  "meta-title": "Account Details",
  "past-values-heading": "Past Values",
  "update-value": "Update Value",
  "edit-details": "Edit Details",
  "delete-account-heading": "Delete \"{{name}}?\"",
  "delete-account-body": "Deleting this {{accountType}} will result in its removal from your net worth, as well as the permanent deletion of any historical data associated with this account.",
  "delete-account-button": "Delete {{text}}",
  "last-updated": "Last updated {{age}} ago",

  "change-account-type": "Change Account Type",
  "change-account-type-nickname-input-label": "Account Nickname",

  "merge": "Merge",
  "merge-account-button": "Continue...",
  "merge-account-heading": "Merge Account",
  "merge-options-title": "Available Accounts",
  "merge-account-description": "Choose an account from the list below to merge with the {{accountType}} \"{{accountName}}.\"",

  "complete-account-merge-heading": "Complete Account Merge?",
  "complete-account-merge-description": "Merging accounts cannot be undone. Are you sure you'd like to combine these two accounts?\n\nThe histories of these two accounts will be combined and the  name,  type, and current value of the first account will be preserved.",
  "complete-account-merge-button": "Merge Accounts",

  "related-documents": "Related Documents",
  "date-added": "Date Added:",
  "by": "by",
  "download": "Download",
  "view": "View",
  "delete": "Delete",
  "modal-title-delete-document": "Delete {{- fileName}}",
  "modal-body-delete-document": "This document was uploaded by {{userName}}. Deleting it will remove it from their account. This action cannot be undone.",
  "modal-confirm-delete-document": "Delete Document",

  "linked-account-connection-error-banner-baa": "Looks like there’s a problem with this account. Head to <a>ByAllAccounts</a> to find a way to fix it.",
  "linked-account-connection-error-banner-plaid": "This connected account is not updating and needs to be fixed by your client."
}
