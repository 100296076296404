{
  "meta-title": "Settings",

  "nav-link-account": "My Account",
  "nav-link-firms": "Firm Settings",

  "software-token-mfa-btn-title": "2-Step Verification",
  "software-token-mfa-authenticate-forgot-password": "Forgot your password?",
  "software-token-mfa-btn-body": "An additional layer of security for your account when you login.",
  "software-token-mfa-verify-prompt": "First, let's verify your identity.",
  "software-token-mfa-scan-app-prompt": "Great! Now scan the code with your authenticator app.",
  "software-token-mfa-pick-friendly-device-name-option-title": "Choose your authenticator app",
  "software-token-mfa-enter-code-title": "Enter Code",
  "software-token-mfa-enter-code-desc": "Enter the one-time code from your authenticator app to finish setup.",
  "software-token-mfa-enter-code-btn": "Verify Code",
  "software-token-mfa-success-title": "Success!",
  "software-token-mfa-success-body": "You’ve finished setting up 2-step verification. Next time you sign in to Elements, you’ll use your authenticator app to verify your identity.",
  "software-token-mfa-success-btn": "Got it",
  "software-token-mfa-disable-prompt": "Enter the code from authenticator.",
  
  "change-email-label": "Enter your new email address",
  "change-email-btn": "Verify Email Address",
  "change-email-heading-1": "Almost there",
  "change-email-heading-2": "Check your inbox and confirm your new email address.",
  "change-email-body-1": "Just click the \"Confirm Email Address\" button in the email we've just sent to",
  "change-email-body-2": "If you've received multiple verification emails, please use the newest verification link.",
  "change-email-body-3": "Failure to verify you remail address will result in being unable to sign in to Elements.",
  "change-email-footer": "Didn't receive the verification email?",
  "change-email-footer-link": "Resend Verification Email",
  "change-email-verified-title":"Thanks for verifying your new email",
  "change-email-unverified-title": "Error - verification unsuccessful",
  "change-email-verified-body":"Now that your new email address has been verified, you can use it to log into your Elements account.",
  "change-email-unverified-body": "Please try again. If you've received multiple verification emails, please use the newest verification link.",
  "change-email-resend-verification-notification": "Verification email resent to {{email}}",
  "change-email-unverified-email-tooltip": "Unverified email address. Click to resend verification.",

  "err-generic": "Oops, something went wrong. Please try again.",
  "err-invalid-email": "Invalid email.",
  "err-invalid-password": "Invalid password.",
  "err-invalid-device-friendly-name": "Invalid authenticator app.",
  "err-additional-auth-challenges": "Unable to complete request, please sign out and sign in, then try again.",
  "err-invalid-totp-code": "Incorrect code. Please double-check your verification code and try again.",
  "err-invalid-credentials": "The Email or Password you entered was incorrect. Please try again.",
  "err-invalid-password-complexity": "Password must be at least 10 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
  "err-invalid-password-same-as-current": "New password cannot be the same as current password.",
  "err-password-change-limit-exceeded": "You have exceeded the maximum number of password changes allowed. Please try again later.",
  "err-invalid-password-try-again": "The password you entered doesn't match our records. Please try again.",
  "err-passwords-dont-match": "Passwords don't match. Double check and try again.",

  "password-updated-successfully": "Your password was successfully updated.",
  "update-password-btn": "Update Password",
  "update-password-title": "Update Password",
  "password-current": "Current Password",
  "password-new": "New Password",
  "password-new-confirm": "Confirm New Password"
}
