{
  "clients": "Clients",
  "active": "Active",
  "onboarding": "Onboarding",
  "prospects": "Prospects",
  "invitations": "Invitations",
  "subscribers": "Subscribers",
  "add-client": "Add Client",
  "active-clients-meta-title": "Active Clients",
  "onboarding-clients-meta-title": "Onboarding Clients",
  "prospect-clients-meta-title": "Prospect Clients",
  "subscriber-clients-meta-title": "Subscriber Clients",
  "empty-active": "This is where you'll see the list of your active clients—but right now it's a little lonely in here. Use the Add Client button below to get started.",
  "empty-invitations": "This is where you'll see the list of your outstanding invitations—but right now it's a little lonely in here. Use the Add Client button below to get started.",
  "empty-prospects": "This is where you'll see the list of your prospects—but right now it's a little lonely in here. Use the Add Client button below to get started.",
  "empty-onboarding": "This is where you'll see the list of your clients during their onboarding process—but right now it's a little lonely in here. Use the Add Client button below to get started.",
  "empty-group": "This is a custom client group that you created, but it’s empty right now. Now go find some of your existing clients and add them to this custom group.",
  "deleted-invitation-for": "Deleted invitation for {{- for}}.",
  "cancel-invitation-modal-title": "Cancel Invitation to Elements?",
  "cancel-invitation-modal-body": "Cancelling this client's invitation to the Elements mobile app will deactivate any outstanding invitations.  They will be unable to access the app until a new invitation is sent.",
  "cancel-invitation-modal-okText": "Cancel Invitation",
  "mark-as-completed": "Mark as Completed",
  "onboarding-completed-modal-title": "Mark Onboarding as Completed?",
  "onboarding-completed-modal-body": "Marking onboarding as complete will move this client to \"Active,\" and we'll start to track progress (for example, net worth).\n\n Any app invitations sent to the client after this point will not have the option of \"client led onboarding\" in the app (we assume that's now completed). \n\n This status change cannot be undone.",
  "onboarding-completed-modal-okText": "Complete Onboarding",
  "convert-to-client": "Convert to Client",
  "remove-prospect-modal-title": "Remove Prospect?",
  "remove-prospect-modal-body": "Removing this prospect will drop them from your list of prospects, and if they've been given access to the Elements mobile app, it will remove their access. This action cannot be undone.",
  "remove-prospect-modal-okText": "Remove Prospect",
  "convert-to-client-modal-title": "Convert to Client?",
  "convert-to-client-modal-body": "Congratulations on your new client! By converting this prospect to a client, they will be placed in your “Onboarding” group.  We won't start their progress until you mark their onboarding as completed to better ensure progress is being measured from a complete baseline.",
  "send-invitation-body": "Verify the email addresses below and send the invitation to {{- who}} to join you on Elements.",
  "send-invitation": "Send Invitation",
  "add-client-body": "Just enter a name to get going! You can choose to send them an Elements invite now or later.",
  "add-client-send-invitation": "Send Elements invitation now",
  "add-client-add-partner": "Add Spouse / Partner",
  "add-client-relationship-placeholder": "Select Relationship",
  "add-client-enable-client-led-onboarding": "Enable client-led onboarding",
  "old-add-client-body": "Add your clients and they'll be invited to download the Elements app and begin filling in their financial information.",
  "invitation-resent-to": "Invitation resent to {{- to}}.",
  "resend-invitation-body": "Need to send another invitation to your client? No problem. Verify the information below and hit that “Resend” button.",
  "invitation-sent": "Invitation Sent",
  "clients-menu-add-client": "Add New Client",
  "clients-menu-invite-links": "Invite Links",
  "invite-links-heading": "Invite Links",
  "invite-links-empty-description": "Create a Client Invite Link that you can use to quickly and efficiently invite as many clients to Elements as you want",
  "invite-links-empty-action": "Create Invite Link",
  "invite-links-item-menu-copy": "Copy Invite Link",
  "invite-links-item-menu-download-qr": "Download QR Code",
  "invite-links-item-menu-edit-details": "Edit Details",
  "invite-links-item-menu-delete": "Delete",
  "invite-link-heading": "Invite Link",
  "invite-link-campaign-name-label": "Campaign Name",
  "invite-link-form-headline-label": "Form Headline",
  "invite-link-form-description-label": "Form Description",
  "invite-link-preview-text": "Preview Create Account Form",
  "invite-link-copied-to-clipboard-description": "Invite link URL for “{{- campaignName}}” copied to the clipboard.",
  "invite-link-qr-code-download-description": "Initiated QR code download for “{{- campaignName}}”.",
  "invite-link-delete-modal-title": "Delete Invite Link “{{- campaignName}}”",
  "invite-link-delete-modal-body": "Deleting this link will deactivate any existing links previously shared for this campaign. If an individual attempts to use a deleted link they will see an error page and be directed to ask for an updated link.\n\nIf you want to keep using this campaign but need to replace the URL for some reason, just create a new campaign with the same exact name.",
  "invite-link-delete-modal-ok-action": "Delete Invite Link",
  "onboarding-phone-view-01-alt": "onboarding phone view - get started",
  "onboarding-phone-view-02-alt": "onboarding phone view - income",
  "onboarding-phone-view-03-alt": "onboarding phone view - spending",
  "onboarding-phone-view-04-alt": "onboarding phone view - your people",
  "onboarding-phone-view-05-alt": "onboarding phone view - add person",
  "onboarding-phone-view-06-alt": "onboarding phone view - cash accounts",
  "onboarding-phone-view-07-alt": "onboarding phone view - add cash account",
  "onboarding-phone-view-08-alt": "onboarding phone view - checking account form",
  "onboarding-phone-view-09-alt": "onboarding phone view - cash accounts table",
  "onboarding-phone-view-10-alt": "onboarding phone view - investments",
  "onboarding-phone-view-11-alt": "onboarding phone view - investments table",
  "onboarding-phone-view-12-alt": "onboarding phone view - savings",
  "onboarding-phone-view-13-alt": "onboarding phone view - edit savings",
  "onboarding-phone-view-14-alt": "onboarding phone view - real estate",
  "onboarding-phone-view-15-alt": "onboarding phone view - add real estate",
  "onboarding-phone-view-16-alt": "onboarding phone view - primary residence form",
  "onboarding-phone-view-17-alt": "onboarding phone view - real estate table",
  "onboarding-phone-view-18-alt": "onboarding phone view - business",
  "onboarding-phone-view-19-alt": "onboarding phone view - add business",
  "onboarding-phone-view-20-alt": "onboarding phone view - debts",
  "onboarding-phone-view-21-alt": "onboarding phone view - add debt",
  "onboarding-phone-view-22-alt": "onboarding phone view - student loan form",
  "onboarding-phone-view-23-alt": "onboarding phone view - debts table",
  "onboarding-phone-view-24-alt": "onboarding phone view - insurance",
  "onboarding-phone-view-25-alt": "onboarding phone view - life insurance form",
  "onboarding-phone-view-26-alt": "onboarding phone view - insurances overview",
  "onboarding-phone-view-27-alt": "onboarding phone view - taxes",
  "onboarding-phone-view-28-alt": "onboarding phone view - add taxes",
  "onboarding-phone-view-29-alt": "onboarding phone view - taxes overview",
  "onboarding-phone-view-30-alt": "onboarding phone view - success",
  "onboarding-phone-view-31-alt": "onboarding phone view - completed onboarding",
  "export-clients": "Export Clients...",
  "export-csv": "Export CSV",
  "export-option-label": "Choose the clients to be included in your export.",
  "clients-source-advisor": "Advisor",
  "client-list-search-box-placeholder": "Find client by name...",
  "billing-error": "Uh oh. There seems to be a problem with your billing. Let's get that fixed.",
  "billing-error-action": "Go to Billing",

  "experience-prospect-discovery-banner-title": "Experience Client Onboarding For Yourself",
  "experience-prospect-discovery-banner-body": "Take a tour of the client onboarding flow while you enter your own financial data. Once your information is in the system, you’ll be able to view it here like you would for any of your clients.",
  "experience-prospect-discovery-banner-call-to-action": "Get Started",

  "experience-prospect-discovery-modal-title": "Experience Client Onboarding For Yourself!",
  "experience-prospect-discovery-modal-body": "Take a tour of the client onboarding flow while you enter your own financial data. Once your information is in the system, you’ll be able to view it here like you would for any of your clients.",
  "experience-prospect-discovery-modal-call-to-action": "Start Onboarding",
  "experience-prospect-discovery-modal-secondary-action": "Remind Me Later",

  "client-groups-add-to-group": "Add to Group...",
  "client-groups-move-to-group": "Move to Group...",
  "client-groups-remove-from-all-groups": "Remove from All Custom Groups",
  "client-groups-choose-a-group": "Choose a Group for {{- name}}",
  "client-groups-create-new-group": "Create New Group",
  "client-groups-name-group": "New Group",

  "client-groups-moved-to-group": "{{- name}} moved to \"{{- group}}\"",
  "client-groups-added-to-group": "{{- name}} added to \"{{- group}}\"",
  "client-groups-removed-from-group": "{{- name}} removed from \"{{- group}}\""
}
