{
  "notes": "Notes",
  "notes-empty": "Add notes.",
  "immediate-family": "Immediate Family",
  "immediate-family-empty": "Add immediate family",
  "family-age": ", Age {{age}}",
  "family-age-months": ", Age {{age}} Months",
  "net-worth-progress": "Net Worth Progress",
  "annual-spending": "Annual Spending*",
  "personal-financial-statement": "Personal Financial Statement",
  "total-assets": "Total Assets",
  "total-debts": "Total Debts",
  "remove-app-access": "Remove App Access",
  "invitation-sent": "Invitation sent {{when}}",
  "mark-onboarding-as-completed": "Mark Onboarding as Completed",
  "onboarding-banner-body": "We’ll hold off on calculating progress in reports until you let us know {{- name}} {{isAre}} fully onboarded.",
  "remove-access-modal-ok": "Remove Access",
  "remove-access-modal-title": "Remove Client Access to Elements?",
  "remove-access-modal-body": "By removing client access to Elements they will no longer be able to access the Elements app.",
  "resend-invitation-body": "Verify the email addresses below and resend the invitation to {{- who}} to join you on Elements.",
  "important-people": "Important People",
  "other-relationship": "Other Relationships",
  "create-one-page-plan": "Create One-Page Plan",
  "one-page-plan-banner-text": "Don't forget—creating a One Page Plan for your client is a great way to help them stay focused and make progress.",
  "edit-financial-purpose-meta-title": "Edit the client's financial purpose",
  "edit-financial-purpose-heading": "Edit Financial Purpose",
  "edit-financial-purpose-placeholder": "Add your client’s financial purpose here...",
  "edit-goals-heading": "Edit Goals",
  "edit-goals-meta-title": "Create, edit, and delete financial goals for this client",
  "client-goals-empty": "Add client goals.",
  "client-next-steps-empty": "Add next steps.",
  "edit-next-steps-meta-title": "Create, edit, and delete financial next steps for this client",
  "edit-next-steps-heading": "Edit Next Steps",
  "add-individual": "Add Individual",
  "edit-individual": "Edit Individual",
  "remove-individual-modal-title": "Remove Individual?",
  "remove-individual-modal-body": "Removing this person will delete them from your client’s profile and they will lose their access to the Elements app. Additionally, any assets or debts assigned to them will revert their ownership back to the primary head-of-household. This action cannot be undone.",
  "remove-individual-modal-ok": "Remove Individual",
  "prospect-banner-body_one": "{{- name}} is a prospect. Convert this prospect to a client to move them to your “Onboarding Group”",
  "prospect-banner-body_other": "{{- name}} are prospects. Convert them to a client to move them to your “Onboarding Group”.",
  "include-debt-payments": "*include debt payments",
  "remove-client": "Remove Client",
  "remove-client-modal-title": "Remove Client?",
  "remove-client-modal-body": "Removing a client will delete them from your list of clients and cannot be undone.",
  "remove-client-notification": "You have removed {{- user}} from your client list.",
  "decayed-percentage": "{{- percentage}}% of balances are decayed",

  "client-page-household-income-button-label": "Household Income",
  "client-page-annual-spending-button-label": "Annual Spending",
  "client-page-scorecard-button-no-report": "Report unavailable",
  "client-page-scorecard-full-screen-button": "Full Screen",

  "income-page-heading": "Income & Spending",
  "income-page-description": "Manage household income sources and spending estimates here.",
  "income-page-empty-description": "Add your household income sources to estimate the amount you expect to earn during the current year.",
  "income-page-segmented-control-income": "Income Sources",
  "income-page-segmented-control-spending": "Spending",
  "income-page-add-income-source-button": "Add Income Source",
  "income-page-total-income-row-title": "Total Household Income",

  "spending-page-description": "Make sure to include all debt payments (currently {{- debtPaymentsDisplay}} {{- frequencySuffix}}) in this estimate.  We'll back out the Debt Payments from Burn Rate so they don't get double counted with Debt Rate too.",
  "spending-page-form-spending-amount-input-label": "Estimated Spending",

  "report-preview-page-action-button": "Download Report",
  "report-preview-page-report-name": "{{- givenName}} {{- familyName}}'s {{reportTypeDisplay}} ({{dateDisplay}})",
  "report-preview-error-page-modal-title": "Hmmmm...",
  "report-preview-error-page-modal-body": "Looks like we’re having trouble loading that report right now. Try again later. If this is happening to you frequently, contact us to help get to the bottom of this error.",
  "report-preview-error-page-modal-primary-action": "Contact Us",

  "download-element-report": "Download {{element}} Report",
  "download-report": "Download Report",
  "add-to-client-group": "Add to Group...",
  "remove-all-custom-groups": "Remove from All Custom Groups",
  "client-groups-move-to-group": "{{- name}} moved to \"{{- group}}\"",
  "client-groups-removed-from-group": "{{- name}} removed from \"{{- group}}\""
}
