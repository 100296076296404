{
  "meta-title": "Insight. {{- clientName}}: {{insightAction}}",
  "com-tip": "Communication Tip",
  "total-debt": "Total Debt",
  "annual-debt-payments": "Annual Debt Payments",
  "decayed-ratio": "{{amount}} is decayed ({{percentage}})",
  "monthly-payment-frequency": "{{amount}}/mo",
  "monthly-payment-and-extra-payment-frequency": "{{amount}}(+{{extraAmount}})/mo",
  "yearly-payment-frequency": "{{amount}}/yr",
  "missing-payment-info": "(missing payment info)",
  "interest-rate-breakdown": "{{interest}}% {{rateType}}",
  "debt-item-title": "{{loanType}} \"{{name}}\"",
  "savings-item-title": "{{type}} \"{{name}}\"",
  "extra-payments-item-title": "{{type}} (Extra Payments) \"{{name}}\"",
  "related-elements": "Related Elements",
  "dismiss-body": "When you dismiss an insight, it drops off your list. After a few months, if the conditions for that insight still apply, we’ll send you a new notification.",
  "dismiss-title": "What to expect when dismissing an insight...",
  "dismiss-ok": "Got It",
  "dismiss-checkbox": "Don't show this again",
  "dismiss-for": "Dismissed insight for {{name}}.",
  "remind-me-ellipsis": "Remind me...",
  "remind-me": "Remind Me",
  "dismiss": "Dismiss",
  "stale-insight-action": "Return to Your List",
  "stale-insight-body": "It looks like this insight no longer applies. Sometimes your clients’ situation can change fast. At any rate, whatever the conditions were that triggered this insight are no longer valid. \n Long story short, you don’t need to worry about this anymore.",
  "stale-insight-title-one": "Whoops.",
  "stale-insight-title-two": "Nothing to see here.",
  "total-liquid-assets": "Total Liquid Assets",
  "spending": "Estimated Annual Spending",
  "personal-cash": "Personal Cash",
  "message-box-recipient-subtitle": "{{givenName}} {{familyName}} ({{relationshipType}})",
  "add-a-report": "Add a Report",
  "subject": "Subject:",
  "bcc": "Bcc:",
  "to": "To:",
  "suggested-message": "Suggested Message",
  "recipients-commas": "{{l}}, {{r}}",
  "recipients-and-commas": "{{l}}, and {{r}}",
  "recipients-and": "{{l}} and {{r}}",
  "remind-me-title": "Remind me to {{action}}:",
  "tomorrow": "Tomorrow",
  "in-three-days": "In Three Days",
  "in-week": "In One Week",
  "in-month": "In One Month",
  "remind-for": "We’ll remind you about that insight for {{for}} {{until}}.",
  "annual-savings": "Annual Savings",
  "pre-tax-savings": "Pre-Tax Savings",
  "after-tax-savings": "After-Tax Savings",
  "education-savings": "Education Savings",
  "cash-savings": "Cash Savings",
  "total-annual-savings": "Total Annual Savings",
  "copy-message-text": "Copy Message Text",
  "insight-no-data-considerations-subtitle": "Considerations when no {{elementName}} exists",
  "insight-conversation-considerations-subtitle": "Considerations for this conversation",
  "insight-score-considerations-subtitle": "Considerations For {{preposition}} “{{scoreCategory}}” {{elementName}}",
  "annual-taxes": "Annual Taxes",
  "burn-rate-annual-spending": "Annual Spending\n(excludes debt payments)"
}
