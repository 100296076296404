{
  "view-selector-option-basic-active-list": "Basic Client List",
  "view-selector-option-basic-onboarding-list": "Basic Onboarding List",
  "view-selector-option-basic-prospect-list": "Basic Prospect List",
  "view-selector-score-separator": "Score Assessment Focus",
  "view-selector-assessment": "{{elementName}} Assessment",

  "active-clients-saod-meta-title": "Active Clients Score Assessments on Demand",
  "onboarding-clients-saod-meta-title": "Onboarding Clients Score Assessments on Demand",
  "prospects-clients-saod-meta-title": "Prospect Clients Score Assessments on Demand",
  "subscribers-clients-saod-meta-title": "Subscriber Clients Score Assessments on Demand",

  "header-title-household": "Household",
  "header-title-savings": "Savings",
  "header-title-income": "Income",
  "header-title-net-worth": "Net Worth",
  "header-title-liquid-assets": "Liquid Assets",
  "header-title-annual-spending": "Annual Spending",
  "header-title-related-elements": "Related Elements",
  "header-title-debt-payments": "Debt Payments",
  "header-title-business-equity": "Business Equity",
  "header-title-spending-less-debt": "Spending (Less Debt)",
  "header-title-real-estate-equity": "Real Estate Equity",
  "header-title-qualified-assets": "Qualified Assets",
  "decay-insights-tooltip": " of liquid asset data is decayed.",
  "column-settings-header": "Column Settings"
}
