{
  "dashboard-sidebar-support-resources-tooltip": "Click here for answers or to submit a support request.",
  "dashboard-sidebar-support-help-center": "Help Center",
  "dashboard-sidebar-support-getting-started-video": "Getting Started Video",

  "welcome-modal-title": "Getting Started with Elements",
  "welcome-modal-body": "Welcome to Elements! Watch this video to learn all the basics about how Elements can help you and your clients quickly assess their financial health.",
  "welcome-modal-video-title": "Welcome to Elements",
  "welcome-modal-video-thumbnail-alt": "Getting Started Video Thumbnail",

  "custom-groups-section-title": "Custom Groups",
  "custom-groups-create-modal-title": "New Custom Group",
  "custom-groups-create-name-label": "Group Name",
  "custom-groups-create-name-placeholder": "Required",
  "custom-groups-create-error": "Unable to create custom group",
  "custom-groups-delete-group": "Delete Group",
  "custom-groups-delete-confirmation-title": "Delete “{{- groupName}}” Custom Group?",
  "custom-groups-delete-confirmation-body": "Deleting this group cannot be undone. All clients currently in this group will be unassigned.",
  "custom-groups-delete-confirmation-button": "Delete Custom Group",
  "custom-groups-delete-error": "Unable to delete group",
  "custom-groups-rename-group": "Rename Group",
  "custom-groups-rename-modal-title": "Rename Custom Group",
  "custom-groups-rename-error": "Unable to rename group"
}
