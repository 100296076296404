{
  "max-file-size": "Unable to upload file as it exceeds maximum allowed file size of 7MB",
  "unable-to-upload-file": "Unable to upload file",

  "sign-in-generic-error": "Something went wrong. Please try again later.",

  "baa-unable-revoke-user-access": "Unable to remove integration. Please try again later.",
  "baa-unable-to-request-access": "Unable to request access for Morningstar ByAllAccounts",
  "baa-unable-to-link-accounts": "Unable to link accounts for this client. Please try again later.",
  "baa-unable-remove-assigned-account": "Unable to remove {{- accountName}}. Please try again later.",

  "baa-create-username-modal-login-already-exists": "This username is already taken. Please try another one.",
  "baa-create-username-modal-login-too-long": "Username must be 32 characters or less.",

  "integrations-unable-to-suggest-integration": "Unable to suggest integration(s). Please try again later.",

  "generic-something-unexpected-happened": "Something unexpected happened.",

  "messaging-create-conversation-some-failed": "Unable to send message to {{numberOfFailed}} out of {{numberOfTotal}} clients.",
  "messaging-unable-to-send-message": "Unable to send message",
  "messaging-unable-to-toggle-mute": "Unable to toggle conversation mute status",
  "messaging-unable-to-mark-conversation-read": "Unable to mark conversation as read",
  "messaging-resend-message-some-failed": "Unable to resend {{numberOfFailed}} out of {{numberOfTotal}} messages.",

  "insurance-unable-to-create": "Unable to create insurance policy. Please try again later",
  "insurance-unable-to-delete": "Unable to delete insurance policy. Please try again later",
  "insurance-unable-to-update": "Unable to update insurance policy. Please try again later",

  "income-source-unable-to-create": "Unable to create income source. Please try again later",
  "income-source-unable-to-update": "Unable to update income source. Please try again later",
  "income-source-unable-to-archive": "Unable to archive income source. Please try again later",

  "firm-member-unable-to-update-email-signature": "Unable to update email signature. Please try again later",

  "firm-unable-to-update": "Unable to update firm. Please try again later",

  "financial-goals-unable-to-create": "Unable to create financial goal. Please try again later",
  "financial-goals-unable-to-delete": "Unable to delete financial goal. Please try again later",
  "financial-goals-unable-to-update": "Unable to update financial goal. Please try again later",
  "financial-goals-unable-to-reorder": "Unable to reorder financial goal. Please try again later",

  "financial-next-steps-unable-to-create": "Unable to create financial next step. Please try again later",
  "financial-next-steps-unable-to-update": "Unable to update financial next step. Please try again later",
  "financial-next-steps-unable-to-delete": "Unable to delete financial next step. Please try again later",
  "financial-next-step-unable-to-reorder": "Unable to reorder financial next step. Please try again later",

  "persons-unable-to-create": "Unable to create person. Please try again later",
  "persons-unable-to-delete": "Unable to delete person. Please try again later",
  "persons-unable-to-update": "Unable to update person. Please try again later",
  "persons-unable-to-remove-app-access": "Unable to remove app access. Please try again later",

  "firm-client-unable-to-update-notes": "Unable to update client notes. Please try again later",
  "firm-client-unable-to-update-financial-purpose": "Unable to update client financial purpose. Please try again later",
  "firm-client-unable-to-invite": "Unable to send client invitation. Please try again later",
  "firm-client-unable-to-resend-invitation": "Unable to resend client invitation. Please try again later",
  "firm-client-unable-switch-onboarding": "Unable to change onboarding type for client. Please try again later",
  "firm-client-unable-to-update-spending": "Unable to update client spending. Please try again later",

  "file-attachment-unable-to-create": "Unable to create file attachment. Please try again later",
  "file-attachment-unable-to-delete": "Unable to delete file attachment. Please try again later",

  "file-unable-to-create": "Unable to create file. Please try again later",
  "file-unable-to-upload": "Unable to upload file. Please try again later"
}
