{
  "latest-progress-report-meta-title": "Your latest progress report!",
  "latest-progress-report-heading": "Whoops—looks like you're trying to see your progress report.",
  "latest-progress-report-subheading": "Tap that link in your email from your phone instead. You can only see your progress report in the Elements app.",
  "PFS": "Personal Financial Statement",
  "progress-report": "Progress Report",
  "DR-summary": "Debt Rate Summary",
  "LT-summary": "Liquid Term Summary",
  "QT-summary": "Qualified Term Summary",
  "TT-summary": "Total Term Summary",
  "SR-summary": "Savings Rate Summary",
  "RT-summary": "Real Estate Term Summary",
  "BT-summary": "Business Term Summary",

  "progress-report-verify-data-description": "Jump over to the Elements app to make sure your financial info is ready for your upcoming progress report.",
  "progress-report-verify-data-title": "Update your data in the app<dot>.</dot>"
}
