{
  "integrations-plaid": "Plaid",
  "integrations-baa": "ByAllAccounts",
  "integrations-linked-account-tooltip-connected": "connected",
  "integrations-linked-account-tooltip-disconnected": "disconnected",

  "integrations-page-heading": "Integrations",
  "integrations-page-meta-title": "Elements Third Party Integrations",
  "integrations-page-meta-description": "Check out our third party integrations!",
  "integrations-page-description": "Set up integrations with other valuable services to supercharge your Elements experience.",

  "baa-integration-getting-started-link": "Getting Started",
  "baa-integration-user-guide-thumb-title": "Getting Started",
  "baa-integration-user-guide-thumb-description": "Open this step-by-step guide for help setting up the ByAllAccounts integration.",
  "baa-integration-meta-title": "Morningstar ByAllAccounts",
  "baa-integration-meta-description": "Elements's Morningstar ByAllAccounts integration",
  "baa-integration-thumb-title": "By All Accounts",
  "baa-integration-thumb-action-button": "View Details",
  "baa-integration-thumb-description": "Data aggregator for connecting your managed accounts to your clients in Elements.",

  "zapier-integration-thumb-title": "Zapier",
  "zapier-integration-thumb-action-button": "View Details",
  "zapier-integration-thumb-description": "Connect your Zapier account for seamless productivity and integration with Elements.",

  "coming-soon-integration-thumb-title": "Coming Soon…",
  "coming-soon-integration-thumb-action-button": "View Details",
  "coming-soon-integration-thumb-description": "More integrations are coming soon. Any recommendations? Let us know.",

  "suggest-integration-meta-title": "Suggest Integration",
  "suggest-integration-meta-description": "Suggest which services you'd like Elements to integrate with next",
  "suggest-integration-modal-title": "Suggest Integrations",
  "suggest-integration-modal-body": "We’d love to hear which services you’d like to see us integrate with next. Your input will help us with planning and priority.",
  "suggest-integration-modal-input-label": "Your Suggested Integrations",
  "suggest-integration-success-message": "Suggestion successfully submitted!",

  "baa-view-header-back-button": "Integrations",
  "baa-view-header-menu-user-guide": "Integration User Guide",
  "baa-view-header-menu-go-to-baa": "Go to By All Accounts",
  "baa-view-header-menu-change-credentials": "Edit Username/Password",
  "baa-view-header-menu-remove-integration": "Remove Integration",

  "baa-view-heading": "Morningstar® ByAllAccounts™",
  "baa-view-description": "Choose a client and select from your available connected accounts to link them to your clients.",

  "baa-view-client-households-table-header-title": "Client Households",
  "baa-view-client-households-table-search-placeholder": "Find by name",
  "baa-view-client-households-table-empty-text": "You have not added anyone to your client list.",
  "baa-view-client-households-table-select-client-banner": "Select a client to add those accounts to their net worth summary.",

  "baa-view-connected-accounts-table-search-placeholder": "Find by name or account number",
  "baa-view-connected-accounts-table-header-title": "Unassigned Accounts",
  "baa-view-connected-accounts-table-empty-text": "There are no available accounts.",
  "baa-view-connected-accounts-table-view-assigned-menu-option": "View Assigned Accounts",

  "baa-view-selected-client-card-back-button": "Back to Client Households",
  "baa-view-selected-client-card-action-button": "Continue...",
  "baa-view-selected-client-card-description": "Select from your available connected accounts to link them to your clients.",
  "baa-view-selected-client-card-accounts-linked-notification-others_one": "and one other account ",
  "baa-view-selected-client-card-accounts-linked-notification-others_other": "and {{count}} other accounts ",
  "baa-view-selected-client-card-accounts-linked-notification": "{{- names}} {{others}}successfully linked to {{- clientName}}.",

  "baa-request-access-view-header": "Morningstar® ByAllAccounts™",
  "baa-request-access-view-body": "The only financial data aggregator exclusively focused on the needs of investors, advisors, and the enterprises who support them.\n\nGet started by requesting access to ByAllAccounts™.",
  "baa-request-access-view-action-button": "Request Access",

  "baa-account-created-modal-title": "Account Created!",
  "baa-account-created-modal-description": "The next step is heading to ByAllAccounts, signing in, and authenticating with your custodian. Your username and temporary password are below.",
  "baa-account-created-modal-username": "Username",
  "baa-account-created-modal-temporary-password": "Temporary Password",
  "baa-account-created-modal-copy-password": "Copy Password",
  "baa-account-created-modal-main-action": "Go to ByAllAccounts",
  "baa-account-created-modal-feeling-stuck-title": "Feeling stuck?",
  "baa-account-created-modal-feeling-stuck-link": "Open Getting Started Guide",

  "baa-create-username-modal-title": "Create Your Username",
  "baa-create-username-modal-body": "Create your username and we’ll create your account and give you a temporary password.",
  "baa-create-username-action-button": "Continue",
  "baa-create-username-multi-firm-input-helper": "If you have access to multiple firms, you will need a unique username for each one",

  "baa-view-client-assigned-accounts-empty-text": "Viewing Assigned Accounts.\n\nSelect an assigned account to see which client it’s linked to.",
  "baa-view-client-assigned-accounts-table-header-title": "Client Households",

  "baa-view-remove-account-success-notification": "{{- accountName}} successfully unassigned  from {{- clientName}}",
  "baa-view-remove-account-modal-title": "Remove Assigned Account?",
  "baa-view-remove-account-modal-body": "Unassigning “{{- accountName}}” from {{- clientName}} will remove that account from their net worth summary.\n\nThis account will then become available to be mapped to another client in Elements.",
  "baa-view-remove-account-modal-ok-text": "Remove Assigned Account",

  "baa-view-assigned-accounts-table-view-assigned-menu-option": "View Unassigned Accounts",
  "baa-view-assigned-accounts-table-header-title": "Assigned Accounts",

  "baa-create-accounts-title": "Add Linked Accounts to Client",
  "baa-create-accounts-description": "Here’s your chance to merge with an account that already exists on the client’s Elements account. If no existing account is specified, we’ll create a new net worth item for your client. ",
  "baa-create-accounts-dropdown-option-new-account": "New Account",

  "baa-remove-integration-confirmation-modal-title": "Remove Integration?",
  "baa-remove-integration-confirmation-modal-body-text": "Removing this integration will permanently delete your ByAllAccounts account and convert all connected accounts from ByAllAccounts into manual accounts. Are you sure you want to remove this integration?",
  "baa-remove-integration-confirmation-modal-ok-text": "Remove ByAllAccounts ",

  "baa-accounts-page-skeleton-loading": "Loading...",
  "baa-accounts-page-skeleton-loading-slow": "Slow connection. Still loading..."
}
