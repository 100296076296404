{
  "create-one-page-plan": "Create a One Page Plan",
  "preview-page-title": "One Page Plan Preview",
  "download": "Download Plan",
  "financial-purpose-placeholder": "Add statement of financial purpose.",
  "financial-purpose": "Financial Purpose",
  "goals": "Goals",
  "add-goal-placeholder": "Add a Client Goal",
  "next-steps": "Next Steps",
  "add-next-step-placeholder": "Add a Next Step",
  "optional-sections": "Optional Sections",
  "nwh": "Net Worth History",
  "income-and-spending": "Annual Income & Spending",
  "scores": "Elements Scores",
  "unsaved-items-modal-title": "You have unsaved items.",
  "unsaved-items-modal-body": "If you continue, any unsaved goals and next steps items will not appear in this One Page Plan.",
  "unsaved-items-done-modal-body": "If you continue, any unsaved goals and next steps items will be lost and gone forever.",
  "unsaved-items-modal-ok-text": "Continue Without Unsaved Items",
  "unsaved-items-done-modal-ok-text": "Save and Done",
  "open-one-page-plan": "Open One-Page Plan"
}
