{
  "baa-meta-title": "Getting Started with Morningstar® ByAllAccounts™",
  "baa-meta-description": "Guide to Element's Morningstar® ByAllAccounts™ integration",
  "baa-page-title": "Getting Started with Morningstar® ByAllAccounts™",
  "baa-guide-title-request-access": "Request Access",
  "baa-guide-description-request-access": "Click <b>Request Access</b> to create a ByAllAccounts account.",
  "baa-guide-title-username-and-password": "Username & Temporary Password",
  "baa-guide-description-username-and-password": "These are your account details for ByAllAccounts. Click <b>Go to ByAllAccounts</b> to launch “AccountView” (ByAllAccounts’ web app) in a separate tab.\n\nNote: You’ll also receive an email from Morningstar with these details.",
  "baa-guide-title-login": "Login with Temporary Password",
  "baa-guide-description-login": "Enter the login info shown in Elements.",
  "baa-guide-title-create-password": "Create New Password",
  "baa-guide-description-create-password": "Create a new password. Be sure to remember or save this password because you’ll  need it for future logins to AccountView.",
  "baa-guide-title-adding-connections": "Add Institutions and Accounts",
  "baa-guide-description-adding-connections": "To add institutions and accounts, go to the <b>Accounts tab -> Create Accounts -> Create Accounts with Credentials</b>",
  "baa-guide-title-select-institution": "Select Institution",
  "baa-guide-description-select-institution": "Search for the institution you want to connect. Click <b>Next</b>.\n\nView our <a>help documentation</a> for guidance around common institutions.",
  "baa-guide-title-enter-credentials": "Enter Institution Credentials",
  "baa-guide-description-enter-credentials": "Enter the credential information required by the institution site.  Click <b>Save and Verify</b> to confirm that the credentials are correct.\n\nNote:\n\n",
  "baa-guide-description-enter-credentials-note-one": "Typically the credentials include user name and password, but may include other inputs. Some setup instructions might involve additional steps outside of ByAllAccounts. In those cases, follow any additional instructions via the link at the top of the page.",
  "baa-guide-description-enter-credentials-note-two": "Password managers can sometimes mistakenly interfere here. Make sure your password manager is not overwriting the user/pass you entered for this institution with the user/pass for AccountView.",
  "baa-guide-description-enter-credentials-final": "If the credentials don’t work, see our <a>help documentation</a> for troubleshooting tips.",
  "baa-guide-title-save-and-create": "Save and Create Financial Accounts",
  "baa-guide-description-save-and-create": "After successful verification, click <b>Save and Create Financial Accounts</b>. Note: If available for the institution, check the box for “automated account management”.\n\nNote: If available for the institution, we recommend checking the box next to ”Let the system automatically manage the accounts on this credential.”",
  "baa-guide-title-finish-aggregation": "Finish Account Aggregation",
  "baa-guide-description-finish-aggregation": "You’ll see a preview of the accounts being aggregated. To complete aggregation, click <b>Save Financial Accounts and Aggregate.</b>",
  "baa-guide-title-tracking-progress": "Tracking Aggregation Progress",
  "baa-guide-description-tracking-progress": "You can track aggregation progress here. When complete, click <b>Refresh Table</b> on the <b>All Accounts Tab</b>.\n\nIf any accounts had an error, click on the <b>Out-of-date Accounts</b> tab.  Then click the Info icon on an account’s row to learn more.  See our <a>help documentation</a> for more troubleshooting tips.",
  "baa-guide-title-view-accounts-on-elements": "View Aggregated Accounts in Elements",
  "baa-guide-description-view-accounts-on-elements": "Go back to your Elements tab where the ByAllAccounts integration page will now show the accounts you've added in AccountView.",
  "baa-guide-title-connect-households": "Assign Accounts to Households",
  "baa-guide-description-connect-households": "Assign aggregated accounts to households by selecting the accounts, the household, and clicking continue. You can search accounts by name or account number.",
  "baa-guide-title-create-or-merge": "Create New Accounts or Merge with Existing",
  "baa-guide-description-create-or-merge": "You can either create these accounts as new ones in an Elements household, or you can merge them with existing accounts.\n\nIf you merge, all balance history and other existing account details will be retained.\n\nIf the account is already connected via Plaid (by the client), we’ll replace that connection with ByAllAccounts so your clients no longer have to maintain those connections themselves.",
  "baa-guide-title-unassigning": "Unassigning Accounts",
  "baa-guide-description-unassigning": "If you accidentally assign an account to the wrong household, you can unassign them here. This will completely delete that account from the household to which it was assigned. That means if you previously merged this account while assigning it, all the merged account history and details will be deleted as well.",
  "baa-guide-title-view-connection-status": "Viewing Connection Status in Client Details",
  "baa-guide-description-view-connection-status": "You can see the new connected accounts on the client detail page by hovering over the connected account status indicator. Your ByAllAccounts connection will replace your clients’ Plaid connections so they no longer have to maintain those credentials themselves.",
  "baa-guide-carousel-pagination": "{{currentIdx}} of 15"
}
