{
  "accept-invite-form-button": "Create Account",
  "accept-invite-form-heading": "Welcome to Elements!",
  "accept-invite-form-subheading": "Let’s get started by creating your account.",

  "invitation-acceptance-success-heading": "You’re in!",
  "invitation-acceptance-success-button": "Open Elements",
  "invitation-acceptance-success-app-download-prompt": "Don’t have Elements installed yet?",
  "invitation-acceptance-success-mobile-subheading": "Your account has been created and you’re now connected to {{- firmName}}.",
  "invitation-acceptance-success-desktop-subheading": "Your account has been created and you’re now connected to {{- firmName}}.\n\nNow head over to your phone and install the Elements app if you haven’t already. You’ll use your email and password to sign in."
}
